import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Navb from '../../Components/Navbar';
import DatePicker from 'react-datepicker';
import httpcontactus from '../../js/http-lms_contactus';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import httpuserprofile from '../../js/http-lms_userprofile';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import SEO from '../../Constant/SEO.json'
import { format } from 'date-fns';
// import axios from 'axios';

const UpdateEvent = () => {
  const [property, setProperty] = useState({
    eventName: '',
    eventDescription: '',
    status: '',
    websiteUrl: '',
    specialInstructions: '',
    eventType: "eb5",
  });

  const [locations, setLocations] = useState([{
    venue: '',
    address: '',
    eventDate: '',
    startTime: '',
    endTime: '',
    countryId: '',
    stateId: '',
    cityId: '',
    zipcode: '',
    mapLink: '',
    eventOrganizerContact: ''
  }]);

  const [addLocations, setAddLocations] = useState([]);

  const [formErros, setFormErrors] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [pop, setPop] = useState(false);
  const [er, setER] = useState();
  const [indexToRemove, setIndex] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const selectedEvent = location.state.selectedEvent;

  
  const today = new Date();
today.setHours(0, 0, 0, 0); 

  useEffect(() => {
    // Pre-fill form with existing event data
    if (selectedEvent) {
      console.log(selectedEvent, "EVENT");
      setProperty({
        eventName: selectedEvent.eventName,
        eventDescription: selectedEvent.eventDescription,
        status: selectedEvent.status,
        websiteUrl: selectedEvent.websiteUrl,
        specialInstructions: selectedEvent.specialInstructions,
        eventType: selectedEvent.eventType,
      });
  
      // Fetch existing locations for the selected event
      httpcontactus
        .get(`eventDetails/getLocationsByEvent?eventId=${selectedEvent.eventId}`)
        .then((result) => {
          console.log(result.data, 'LocationDetails');
          setLocations(result.data);
  
          // Fetch states and cities based on the initially selected country and state
          if (result.data.length > 0) {
            const firstLocation = result.data[0];
            if (firstLocation.countryId) {
              fetchStates(firstLocation.countryId);
            }
            if (firstLocation.stateId) {
              fetchCities(firstLocation.stateId);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'ERRRR');
        });
    }
  
    // Fetch countries list
    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      setCountriesList(res.data);
    });
  }, [selectedEvent]);

  const fetchStates = (countryId) => {
    httpuserprofile
      .get(`/userprofileLMS/getUserStates?countryId=${countryId}`)
      .then((res) => setStatesList(res.data));
  };
  
  const fetchCities = (stateId) => {
    httpuserprofile
      .get(`/userprofileLMS/getUserCities?stateId=${stateId}`)
      .then((res) => setCitiesList(res.data));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: value,
    }));
  };

  function handleClear(){
    setProperty({
      eventName: '',
      eventDescription: '',
      status: '',
      websiteUrl: '',
      specialInstructions: '',
      eventType:"eb5",
    });
  
  
    setLocations([
      {
        venue: '',
        address: '',
        eventDate: '',
        startTime: '',
        endTime: '',
        countryId: '',
        stateId: '',
        cityId: '',
        selectedCountryOption: null,
        selectedStateOption: null,
        selectedCityOption: null,
        zipcode: '',
        mapLink: '',
        eventOrganizerContact: ''
      }
    ]);

  }

  const addLocation = () => {
    setLocations([
      ...locations,
      {
        venue: '',
        address1: '',
        eventDate: '',
        startTime: '',
        endTime: '',
        countryId: '',
        stateId: '',
        cityId: '',
        zipcode: '',
        mapLink: '',
        eventOrganizerContact: '',
        new: true
        // locationId: null, // New locations have no locationId
      }
    ]);
  };

  const convertESTToZulu = (estHour) => {
    // Add 5 hours to EST to get Zulu time
    const utcHour = (parseInt(estHour, 10) + 5) % 24;
    return `${utcHour.toString().padStart(2, '0')}:00Z`; // Format as HH:MMZ
  };

  

  const convertZuluToEST = (zuluTime) => {
    if (!zuluTime) {
      console.warn("Zulu time is missing or invalid:", zuluTime);
      return "";
    }
  
    // Adjust regex to match `HH:MMZ` format
    const match = zuluTime.match(/^(\d{2}):(\d{2})Z$/);
    if (!match) {
      console.warn("Zulu time format does not match HH:MMZ:", zuluTime);
      return "";
    }
  
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
  
    // Convert to UTC date with parsed hours and minutes
    const utcDate = new Date(Date.UTC(1970, 0, 1, hours, minutes));
  
    // Convert to EST (UTC -5 hours)
    const estDate = new Date(utcDate.getTime() - (5 * 60 * 60 * 1000));
  
    // Format to HH:MM AM/PM in EST
    const estHours = estDate.getUTCHours() % 12 || 12;
    const estMinutes = String(estDate.getUTCMinutes()).padStart(2, '0');
    const period = estDate.getUTCHours() < 12 ? 'AM' : 'PM';
  
    const estTime = `${estHours}:${estMinutes} ${period} EST`;
    
    console.log("Converted Zulu to EST:", zuluTime, "->", timeOptions.filter(time => time.display===estTime)?.[0]?.value);
    return timeOptions.filter(time => time.display===estTime)?.[0]?.value;
  };
  
  
  
  

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const period = hour >= 12 ? 'PM' : 'AM';
      const displayHour = hour % 12 === 0 ? 12 : hour % 12; // 12-hour format
      const estTime = `${displayHour}:00 ${period} EST`;
  
      // Store time in 24-hour format for accurate conversion later
      options.push({ display: estTime, value: hour });
    }
    return options;
  };

  
  
  const timeOptions = generateTimeOptions();


  
  const removeLocation = (index) => {
    const locationToRemove = indexToRemove;

    locationToRemove.push(locations[index]);

    setIndex(locationToRemove);

    const updatedLocations = locations.filter((_, i) => i !== index);
    setLocations(updatedLocations);
  };
  

  const handleLocationChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLocations = [...locations];
    console.log(value, 'vvvvvvvvvvv');
    
    // Format eventDate as MM-dd-yyyy, and convert startTime/endTime as needed
    const newValue = name === "eventDate"
      ? format(new Date(value), 'MM-dd-yyyy')
      : (name === 'startTime' || name === 'endTime')
      ? convertESTToZulu(value)
      : value;
  
    updatedLocations[index] = { ...updatedLocations[index], [name]: newValue };
    
    // Update the locations state
    setLocations(updatedLocations);
  };
  





  const handleCountryChange = (index, selectedCountry) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      countryId: selectedCountry ? selectedCountry.countryId : '',
      stateId: '',
      cityId: '',
    };
    setLocations(updatedLocations);
  
    if (selectedCountry) {
      fetchStates(selectedCountry.countryId);
      setCitiesList([]); // Clear cities list if country changes
    }
  };
  
  const handleStateChange = (index, selectedState) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      stateId: selectedState ? selectedState.stateId : '',
      cityId: '',
    };
    setLocations(updatedLocations);
  
    if (selectedState) {
      fetchCities(selectedState.stateId);
    }
  };
  
  const handleCityChange = (index, selectedCity) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      cityId: selectedCity ? selectedCity.cityId : '',
    };
    setLocations(updatedLocations);
  };

  const validate = (values, locations) => {
    const errors = {};
    if (!values.eventName) {
      errors.eventName = 'Event Name is required!';
    }
    if (!values.eventDescription) {
      errors.eventDescription = 'Event Description is required!';
    }
    if (!values.status) {
      errors.status = 'Status is required!';
    }
    if (!values.websiteUrl) {
      errors.websiteUrl = 'Website Url is required!';
    }
    if (!values.specialInstructions) {
      errors.specialInstructions = 'Special Instructions are required!';
    }

    locations.forEach((location, index) => {
      if (!location.venue) {
        errors[`venue-${index}`] = `Venue is required for location ${index + 1}!`;
      }
      if (!location.address) {
        errors[`address-${index}`] = `Address is required for location ${index + 1}!`;
      }
      if (!location.eventDate) {
        errors[`eventDate-${index}`] = `Event date is required for location ${index + 1}!`;
      }
      if (!location.startTime) {
        errors[`startTime-${index}`] = `Start time is required for location ${index + 1}!`;
      }
      if (!location.endTime) {
        errors[`endTime-${index}`] = `End time is required for location ${index + 1}!`;
      }
      if (!location.countryId) {
        errors[`country-${index}`] = `Country is required for location ${index + 1}!`;
      }
      if (!location.stateId) {
        errors[`state-${index}`] = `State is required for location ${index + 1}!`;
      }
      if (!location.cityId) {
        errors[`city-${index}`] = `City is required for location ${index + 1}!`;
      }
      if (!location.zipcode) {
        errors[`zipcode-${index}`] = `Zip Code is required for location ${index + 1}!`;
      }
      if (!location.mapLink) {
        errors[`mapLink-${index}`] = `Map Link is required for location ${index + 1}!`;
      }
      if (!location.eventOrganizerContact) {
        errors[`eventOrganizerContact-${index}`] = `Event Organizer Contact is required for location ${index + 1}!`;
      }
    });
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const propertyErrors = validate(property, locations);

    if (Object.keys(propertyErrors).length !== 0) {
      setFormErrors(propertyErrors);
    } else {
      setFormErrors({});
      const formattedEvent = {
        eventId: selectedEvent.eventId,  // Pass event ID for updating
        eventName: property.eventName,
        eventDescription: property.eventDescription,
        status: property.status,
        createdBy: sessionStorage.getItem('userEmail') || "Madhavi",
        specialInstructions: property.specialInstructions,
        websiteUrl: property.websiteUrl,
        eventLocations: locations.map((location) => ({
          locationId: location.locationId,
          venue: location.venue,
          address: location.address,
          eventDate: location.eventDate,
          startTime: location.startTime,
          endTime: location.endTime,
          countryId: location.countryId,
          stateId: location.stateId,
          cityId: location.cityId,
          zipcode: location.zipcode,
          mapLink: location.mapLink,
          eventOrganizerContact: location.eventOrganizerContact
        }))
      };

      // First, update the event details
      httpcontactus
        .put('/eventDetails/updateEventDetails', formattedEvent)
        .then((response) => {
          if (response.data) {
            // After successful event update, trigger adding locations

            
            locations.forEach((location) => {
              console.log(location.startTime, location.endTime);
              if (location?.new) {const locationData = {
                eventId: selectedEvent.eventId,  // Ensure the eventId is included
                venue: location.venue,
                address: location.address,
                eventDate: location.eventDate,
                startTime: location.startTime,
                endTime: location.endTime,
                countryId: location.countryId,
                stateId: location.stateId,
                cityId: location.cityId,
                zipcode: location.zipcode,
                mapLink: location.mapLink,
                eventOrganizerContact: location.eventOrganizerContact
              };

              // Make an API call for each location
              httpcontactus
                .post('/eventDetails/addNewLocationByEventId', locationData)
                .then((response) => {
                  if (response.data) {
                    console.log(`Location ${location.venue} added successfully`);
                  }
                })
                .catch((error) => {
                  console.error('Error adding location:', error.response.data);
                });}
            });

            indexToRemove.forEach((locationToRemove) => {

              if (locationToRemove?.locationId) {
                // If location already exists, trigger delete API
                httpcontactus
                  .delete(`/eventDetails/deleteLocationById?locationId=${locationToRemove.locationId}`)
                  .then(() => {
                    console.log(`Location ${locationToRemove.locationId} deleted successfully.`);
                    // Remove from state after deletion
          
                  })
                  .catch((err) => {
                    console.error("Error deleting location:", err);
                  });
              }
            })

            // Show popup for successful form submission
            setPop(true);
            setTimeout(() => {
              setPop(false);

              setProperty({
                eventName: '',
                eventDescription: '',
                status: '',
                websiteUrl: '',
                specialInstructions: '',
                eventType:"eb5",
              });
            
            
              setLocations([
                {
                  venue: '',
                  address: '',
                  eventDate: '',
                  startTime: '',
                  endTime: '',
                  countryId: '',
                  stateId: '',
                  cityId: '',
                  selectedCountryOption: null,
                  selectedStateOption: null,
                  selectedCityOption: null,
                  zipcode: '',
                  mapLink: '',
                  eventOrganizerContact: ''
                }
              ]);


              navigate(ROUTES.MY_EVENTS);
              
            }, 1500);
          }
        })
        .catch((error) => {

          console.log(error);
          
          setER(error.response.data);
        });
    }
  };


  function navToPrevRoute() {
    navigate(ROUTES.MY_EVENTS);
  }


  const colourStyles = {
    control: (styles) => ({
      ...styles,
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };


  return (

    <>

<PageSeoComponent {...SEO['update-event']} />
    
    {pop && (
          <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
            <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
              <div>
                <img
                  src={require('../../images/Tick.png')}
                  alt="success"
                  className="img-fluid"
                />
              </div>
              <p className="text-center w213 ">
                Sucessfully event has been added
              </p>
            </div>
          </div>
        )}








<div className="col-lg-12 d-flex mt-5">
          <div
            className=" d-flex align-items-center mt-5 pt-4 pointer add-property-title-wrap"
            onClick={navToPrevRoute}
          >
            <img
              className="pe-3 backarrow"
              src={require('../../images/leftarrow.png')}
              alt="back"
            />
            <h4 className="SectionHeadings mt-2">Update Event</h4>
          </div>
        </div>



   <div className="add-property-page">
    
        <h1>Event Info</h1>
        <Form onSubmit={handleSubmit}>
        <div className="row add-property-form">




        <div className="col-md-4">
                <Form.Group controlId="eventName">
                  <Form.Label className="required">Event Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="eventName"
                    value={property.eventName}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.eventName}</p>
                </Form.Group>
              </div>


            

              <div className="col-md-4">
                <Form.Group controlId="eventDescription">
                  <Form.Label className="required">Event Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="eventDescription"
                    value={property.eventDescription}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.eventDescription}</p>
                </Form.Group>
              </div>



              <div className="col-md-4">
            <Form.Group controlId="status">
                <Form.Label className="required">Status</Form.Label>
                <Form.Select
  name="status"
  value={property.status}
  onChange={handleChange}
  aria-label="Default select example"
>
  <option value="">Select Status</option>
  <option value="Active">Active</option>
  <option value="Inactive">Inactive</option>
</Form.Select>

                <p className="red font12">{formErros.status}</p>
              </Form.Group>
            </div>




            <div className="col-md-4">
                <Form.Group controlId="websiteUrl">
                  <Form.Label className="required">Website URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="websiteUrl"
                    value={property.websiteUrl}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.websiteUrl}</p>
                </Form.Group>
              </div>

              <div className="col-md-4">
                <Form.Group controlId="specialInstructions">
                  <Form.Label className="required">Special Instructions</Form.Label>
                  <Form.Control
                    type="text"
                    name="specialInstructions"
                    value={property.specialInstructions}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.specialInstructions}</p>
                </Form.Group>
              </div>




         </div>

          {/* Location Section */}
          {locations.map((location, index) => (
            <Row key={index} className=" pt-4 mt-3 mb-5 add-property-form add-location">
              <h5 className="mb-4">Location {index + 1}</h5>
              <Col md={4}>
                <Form.Group controlId={`venue-${index}`}>
                  <Form.Label className="required">Venue</Form.Label>
                  <Form.Control
                    type="text"
                    name="venue"
                    value={location.venue}
                    onChange={(e) => handleLocationChange(index, e)}
                  />
                  <p className="red font12">{formErros[`venue-${index}`]}</p>
                </Form.Group>
              </Col>

              {/* Address */}
    <div className="col-md-4">
      <Form.Group controlId={`address-${index}`}>
        <Form.Label className="required">Address</Form.Label>
        <Form.Control
          type="text"
          name="address"
          value={location.address}
          onChange={(e) => handleLocationChange(index, e)}
        />
        {formErros[`address-${index}`] && <p className='red font12'>{formErros[`address-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Event Date */}
    <div className="col-md-4">
      <Form.Group controlId={`eventDate-${index}`}>
      <div className='d-flex flex-column'>
    <Form.Label className="required">Venue Date</Form.Label>
    <DatePicker
      selected={location.eventDate}
      onChange={(date) => handleLocationChange(index, { target: { name: "eventDate", value: date } })}
      minDate={today}
      dateFormat="MM-dd-yyyy"
      className="form-control"
    />
    </div>
         {formErros[`eventDate-${index}`] && <p className='red font12'>{formErros[`eventDate-${index}`]}</p>}
      </Form.Group>
    </div>












    <div className="col-md-4">
  <Form.Group controlId={`startTime-${index}`}>
    <Form.Label className="required">Start Time</Form.Label>
    <Form.Control
      as="select"
      value={convertZuluToEST(location.startTime) || ""}
      onChange={(e) =>
        handleLocationChange(index, { target: { name: 'startTime', value: e.target.value } })
      }
      className="form-control"
    >
      <option value="" disabled>Select Start Time</option>
      {timeOptions.map((time, i) => (
        <option key={i} value={time.value}>
          {time.display}
        </option>
      ))}
    </Form.Control>

    {formErros[`startTime-${index}`] && <p className="red font12">{formErros[`startTime-${index}`]}</p>}
  </Form.Group>
</div>

<div className="col-md-4">
  <Form.Group controlId={`endTime-${index}`}>
    <Form.Label className="required">End Time</Form.Label>
    <Form.Control
      as="select"
      value={convertZuluToEST(location.endTime) || ""}
      onChange={(e) =>
        handleLocationChange(index, { target: { name: 'endTime', value: e.target.value } })
      }
      className="form-control"
    >
      <option value="" disabled>Select End Time</option>
      {timeOptions.map((time, i) => (
        <option key={i} value={time.value}>
          {time.display}
        </option>
      ))}
    </Form.Control>

    {formErros[`endTime-${index}`] && <p className="red font12">{formErros[`endTime-${index}`]}</p>}
  </Form.Group>
</div>














   
    {/* <div className="col-md-4">
      <Form.Group controlId={`startTime-${index}`}>
        <Form.Label className="required">Start Time</Form.Label>
        <Form.Control
          type="text"
          name="startTime"
          value={location.startTime}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`startTime-${index}`] && <p className='red font12'>{formErros[`startTime-${index}`]}</p>}
      </Form.Group>
    </div>

    <div className="col-md-4">
      <Form.Group controlId={`endTime-${index}`}>
        <Form.Label className="required">End Time</Form.Label>
        <Form.Control
          type="text"
          name="endTime"
          value={location.endTime}
          onChange={(e) => handleLocationChange(index, e)}
        />
        {formErros[`endTime-${index}`] && <p className='red font12'>{formErros[`endTime-${index}`]}</p>}
      </Form.Group>
    </div> */}

    {/* Country */}
    <div className="col-md-4">
      <Form.Group controlId={`country-${index}`}>
        <Form.Label className="required">Country</Form.Label>
        <Select
   options={countriesList}
   value={countriesList.find(
     (country) => country.countryId === location.countryId
   )}
   getOptionLabel={(option) => option.countryName}
                    getOptionValue={(option) => option.countryId}
                    onChange={(selected) => handleCountryChange(index, selected)}
  placeholder="Select Country"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}

/>
{formErros[`country-${index}`] && <p className='red font12'>{formErros[`country-${index}`]}</p>}
      </Form.Group>
    </div>


    {/* State */}
    <div className="col-md-4">
      <Form.Group controlId={`state-${index}`}>
        <Form.Label className="required">State</Form.Label>
       
<Select

options={statesList}
                    value={statesList.find(
                      (state) => state.stateId === location.stateId
                    )}
                    getOptionLabel={(option) => option.stateName}
                    getOptionValue={(option) => option.stateId}
                    onChange={(selected) => handleStateChange(index, selected)}




  placeholder="Select State"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}


/>
{formErros[`state-${index}`] && <p className='red font12'>{formErros[`state-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* City */}
<div className="col-md-4">
  <Form.Group controlId={`city-${index}`}>
    <Form.Label className="required">City</Form.Label>
    <Select
      options={citiesList}
      value={citiesList.find(city => city.cityId === location.cityId)} // Update this line
      getOptionLabel={(option) => option.cityName}
      getOptionValue={(option) => option.cityId}
      onChange={(selectedCity) => handleCityChange(index, selectedCity)}
      placeholder="Select City"
      className="multiselectdropdown"
      classNamePrefix="react-select"
      styles={colourStyles}
    />
    {formErros[`city-${index}`] && <p className='red font12'>{formErros[`city-${index}`]}</p>}
  </Form.Group>
</div>


    {/* Zip Code */}
    <div className="col-md-4">
      <Form.Group controlId={`zipcode-${index}`}>
        <Form.Label className="required">Zip Code</Form.Label>
        <Form.Control
          type="text"
          name="zipcode"
          value={location.zipcode}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`zipcode-${index}`] && <p className='red font12'>{formErros[`zipcode-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Map Link */}
    <div className="col-md-4">
      <Form.Group controlId={`mapLink-${index}`}>
        <Form.Label className="required">Map Link</Form.Label>
        <Form.Control
          type="text"
          name="mapLink"
          value={location.mapLink}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`mapLink-${index}`] && <p className='red font12'>{formErros[`mapLink-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Organizer Contact */}
    <div className="col-md-4">
      <Form.Group controlId={`eventOrganizerContact-${index}`}>
        <Form.Label className="required">Organizer Contact</Form.Label>
        <Form.Control
          type="text"
          name="eventOrganizerContact"
          value={location.eventOrganizerContact}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`eventOrganizerContact-${index}`] && <p className='red font12'>{formErros[`eventOrganizerContact-${index}`]}</p>}
      </Form.Group>
    </div>

    {locations.length > 0  && (
      <div className="col-md-4 pt-4">
        <Button variant="danger" onClick={() => removeLocation(index)}>
          Remove Location
        </Button>
      </div>
    )}
  

              {/* Other fields for location */}
              {/* Repeat for address1, eventDate, startTime, endTime, country, state, city, zipcode, mapLink, and eventOrganizerContact */}
            </Row>
          ))}

<div className='col-md-3 py-3'>
<Button onClick={addLocation}>Add More Location</Button>
</div> 

          
<div className='d-lg-flex mt-3'>
          <Button type="submit" className="btn btn-primary">
            Update Event
          </Button>

          <Button className='ms-sm-3   greybtn' onClick={handleClear}>Clear</Button>
          </div>


        </Form>

       
      
    </div>
    
    </>
 
  );
};

export default UpdateEvent;
